import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// Environment
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class CustomersService {

    constructor(private http: HttpClient) {}

    customers () {
        return this.http.get(`${environment.baseUrl}/customers`)
    }

    changeState (_customer) {
        return this.http.get(`${environment.baseUrl}/customers/${_customer['id']}/change-status`)
    }

    store (_customer) {
        return this.http.post(`${environment.baseUrl}/customers`, _customer)
    }

    storeFromOrder (_id, _customer) {
        return this.http.post(`${environment.baseUrl}/customers/${_id}/store-from-order`, _customer)
    }

    storeAddressFromOrder (_customer) {
        return this.http.post(`${environment.baseUrl}/customers/${_customer['id']}/address`, _customer)
    }

    update (_customer, _id) {
        return this.http.put(`${environment.baseUrl}/customers/${_id}`, _customer)
    }

    updateCustomerFromOrder (_customer) {
        return this.http.put(`${environment.baseUrl}/customers/${_customer['id']}/update-customer-from-order`, _customer)
    }

    updateAddressFromOrder (_customer) {
        return this.http.put(`${environment.baseUrl}/customers/${_customer['id']}/update-address-from-order`, _customer)
    }

    getById (_id) {
        return this.http.get(`${environment.baseUrl}/customers/${_id}/edit`)
    }

    getByName (_account, _name) {
    	return this.http.get(`${environment.baseUrl}/customers/${_account}/${_name}/get-by-name`);
    }

    getAddre (_id) {
        return this.http.get(`${environment.baseUrl}/customers/${_id}/get-addre`);
    }

    getAddress (_id) {
        return this.http.get(`${environment.baseUrl}/customers/${_id}/get-address`);
    }
}
