import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

// Environment
import { environment } from "../../environments/environment";

@Injectable({
	providedIn: "root",
})
export class CountriesService {
	private endpoint = "https://restcountries.eu/rest/v2";

	constructor(private http: HttpClient) {}

	/**
	 * Get all countries
	 */
	all() {
		return this.http.get(`${environment.baseUrl}/restcountries`);
	}

	/**
	 * Search a contry per alphacode
	 * @param alphacode string (Alpha code the country example 'VE')
	 */
	getCountryByCode(alphacode: string) {
		return this.http.get(`${this.endpoint}/alpha/${alphacode}`);
	}

	/**
	 * Search a country per name
	 * @param name string (Example 'Venezuela')
	 */
	getCountryByName(name: string) {
		return this.http.get(`${this.endpoint}/name/${name}`);
	}
}
