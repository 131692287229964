import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// Environment
import { environment } from "../../../environments/environment";

@Injectable({
	providedIn: "root"
})
export class OrderService {
	constructor(private http: HttpClient) {}

	validateOrder(_order) {
		return this.http.post(`${environment.baseUrl}/validate/orders`, _order);
	}

	validateDetail(_detail) {
		return this.http.post(
			`${environment.baseUrl}/validate/details`,
			_detail
		);
	}

	validatePrice(_price) {
		return this.http.post(`${environment.baseUrl}/validate/price`, _price);
	}

	updateAccount(_account) {
		return this.http.put(
			`${environment.baseUrl}/orders/${_account["id"]}/account`,
			_account
		);
	}

	createCustomer(_customer) {
		return this.http.put(
			`${environment.baseUrl}/orders/customer`,
			_customer
		);
	}

	updateCustomer(_customer) {
		return this.http.put(
			`${environment.baseUrl}/orders/${_customer["id"]}/customer`,
			_customer
		);
	}

	storeOrder(_order) {
		return this.http.post(`${environment.baseUrl}/orders`, _order);
	}

	storeDetails(_order, _details) {
		return this.http.post(
			`${environment.baseUrl}/details/${_order["id"]}`,
			_details
		);
	}

	storeFile(_order) {
		return this.http.get(
			`${environment.baseUrl}/documents/${_order["id"]}/file-quote`
		);
	}

	updateOrder(_order) {
		return this.http.put(
			`${environment.baseUrl}/orders/${_order["id"]}`,
			_order
		);
	}

	updateDetails(_order, _details) {
		return this.http.put(
			`${environment.baseUrl}/details/${_order["id"]}`,
			_details
		);
	}

	updateFile(_order) {
		return this.http.get(
			`${environment.baseUrl}/documents/${_order["id"]}/update-file`
		);
	}

	destroyDetails(_order, _details) {
		return this.http.post(
			`${environment.baseUrl}/details/${_order["id"]}/remove-articles`,
			_details
		);
	}

	activated() {
		return this.http.get(`${environment.baseUrl}/orders/activated`);
	}

	getIncraseWidth(_width) {
		return this.http.get(`${environment.baseUrl}/terms/${_width}`);
	}

	send(Id) {
		return this.http.post(`${environment.baseUrl}/quotations/send`, {
			Id: Id
		});
	}

	sendWorkOrder(Id: number) {
		return this.http.post(`${environment.baseUrl}/workorders/send`, {
			Id: Id
		});
	}

	sendInvoice(Id: number) {
		return this.http.post(`${environment.baseUrl}/invoices/send`, {
			Id: Id
		});
	}

	getById(_id) {
		return this.http.get(`${environment.baseUrl}/orders/${_id}/edit`);
	}

	getByDate(_from, _to) {
		return this.http.get(
			`${environment.baseUrl}/orders/${_from}/${_to}/get-by-date`
		);
	}

	getPositionsByEmployees() {
		return this.http.get(`${environment.baseUrl}/positions/employees`);
	}

	deleteAssignedUser(_id) {
		return this.http.delete(
			`${environment.baseUrl}/assignments/${_id}/delete`
		);
	}

	cancel(_id) {
		return this.http.get(`${environment.baseUrl}/orders/${_id}/cancel`);
	}

	quotations() {
		return this.http.get(`${environment.baseUrl}/quotations`);
	}

	getByDateFromQuotations(_from, _to) {
		return this.http.get(
			`${environment.baseUrl}/quotations/${_from}/${_to}/get-by-date`
		);
	}

	authorizeQuote(_id) {
		return this.http.get(
			`${environment.baseUrl}/quotations/${_id}/authorize`
		);
	}

	workorders() {
		return this.http.get(`${environment.baseUrl}/workorders`);
	}

	getByDateFromWorkOrders(_from, _to) {
		return this.http.get(
			`${environment.baseUrl}/quotations/${_from}/${_to}/get-by-date`
		);
	}

	authorizeWorkOrder(_id) {
		return this.http.get(
			`${environment.baseUrl}/workorders/${_id}/authorize`
		);
	}

	sign(workorder: any) {
		return this.http.post(
			`${environment.baseUrl}/workorders/${workorder.id}/sign`,
			workorder
		);
	}

	cancelWorkOrder(_quote) {
		return this.http.post(
			`${environment.baseUrl}/workorders/${_quote["id"]}/cancel`,
			_quote
		);
	}

	generateInvoice(_quote) {
		return this.http.get(`${environment.baseUrl}/invoices/${_quote["id"]}`);
	}

	generateOrderSalary(_id) {
		return this.http.get(`${environment.baseUrl}/assignments/order/${_id}`);
	}

	generateAssists(_id) {
		return this.http.get(`${environment.baseUrl}/assists/order/${_id}`);
	}

	assingTimesToAssignment(_assignment) {
		return this.http.post(
			`${environment.baseUrl}/assignments/movements`,
			_assignment
		);
	}

	authorizeAssignment(_assignment) {
		return this.http.get(
			`${environment.baseUrl}/assignments/${_assignment.id}/authorize`
		);
	}

	assingUserToOrder(_assignment) {
		return this.http.post(
			`${environment.baseUrl}/assignments/order`,
			_assignment
		);
	}

	invoices() {
		return this.http.get(`${environment.baseUrl}/invoices`);
	}

	checkIn(_quote) {
		return this.http.get(
			`${environment.baseUrl}/invoices/${_quote["id"]}/factoring`
		);
	}

	getByDateFromInvoices(_from, _to) {
		return this.http.get(
			`${environment.baseUrl}/invoices/${_from}/${_to}/get-by-date`
		);
	}

	work() {
		return this.http.get(`${environment.baseUrl}/orders`);
	}

	getAssignmentsWithActivities() {
		return this.http.get(`${environment.baseUrl}/assignments/activities`);
	}

	getOrderAssignments() {
		return this.http.get(`${environment.baseUrl}/assignments/order`);
	}

	getAssignmentsWithUser() {
		return this.http.get(`${environment.baseUrl}/assignments/movements`);
	}

	getByDateFromMovements(_from, _to) {
		return this.http.get(
			`${environment.baseUrl}/assignments/${_from}/${_to}/movements/get-by-date`
		);
	}
}
