import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// Environment
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class AccountService {

    constructor(private http: HttpClient) {}

    all () {
        return this.http.get(`${environment.baseUrl}/accounts`)
    }

    save (_account) {
        return this.http.post(`${environment.baseUrl}/accounts`, _account)
    }

    uptade (_account) {
        return this.http.put(`${environment.baseUrl}/accounts/${_account['id']}`, _account)
    }

    changeState (_account) {
        return this.http.get(`${environment.baseUrl}/accounts/${_account['id']}/change-status`)
    }

    uptadeFromOrder (_account) {
        return this.http.put(`${environment.baseUrl}/accounts/${_account['id']}/update-from-order`, _account)
    }

    getById (_id) {
        return this.http.get(`${environment.baseUrl}/accounts/${_id}/edit`)
    }
}
