import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// Globals
import { Globals } from '../../../globals';
// Environment
import { environment } from '../../../../environments/environment';


@Injectable({
 	providedIn: 'root'
})

export class ProductService {

  	constructor(private http: HttpClient, public globals: Globals) {}

  	all () {
    	return this.http.get(`${environment.baseUrl}/products`);
  	}
	  
	save (_product) {
    	return this.http.post(`${environment.baseUrl}/products`, _product);
  	}

  	update (_product) {
    	return this.http.put(`${environment.baseUrl}/products/${_product['id']}`, _product);
	}
	
	getServices () {
        return this.http.get(`${environment.baseUrl}/type/services`)
    } 

	getId () {
    	return this.http.get(`${environment.baseUrl}/products/identifier`);
	}
	  
	getById (_id) {
		return this.http.get(`${environment.baseUrl}/products/${_id}/edit`);
	}

	getProductByType (_account, _type) {
  		return this.http.get(`${environment.baseUrl}/products/${_account}/${_type}/get-by-type`);
	}
	 
	getProductByName (_account, _name) {
		return this.http.get(`${environment.baseUrl}/products/${_account}/${_name}/get-by-name`);
   	}
}
