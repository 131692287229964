import { Injectable } from '@angular/core';
// Router
import { HttpClient } from '@angular/common/http';
// Environment
import { environment } from '../../../../environments/environment';

@Injectable({
  	providedIn: 'root'
})

export class GeneralService {

  constructor(private http: HttpClient) {}

	getGenerals () {
		return this.http.get(`${environment.baseUrl}/generals`);
	}

	getPositionsWithConditionals () {
		return this.http.get(`${environment.baseUrl}/generals/positions`);
	}

	updatePoundWithConditionals (pound) {
		return this.http.put(`${environment.baseUrl}/generals/${pound.id}/conditionals`, pound);
	}

	update (_general) {
		return this.http.put(`${environment.baseUrl}/generals/${_general['id']}`, _general);
	}
}
