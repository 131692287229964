import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Globals
import { Globals } from '../../../globals';
// Environment
import { environment } from '../../../../environments/environment';

@Injectable({
  	providedIn: 'root'
})

export class ServiceService {

  	constructor(private http: HttpClient) {}

  	all () {
    	return this.http.get(`${environment.baseUrl}/services`);
 	}

  	save (_service) {
   		return this.http.post(`${environment.baseUrl}/services`, _service);
  	}

  	update (_service) {
		return this.http.put(`${environment.baseUrl}/services/${_service['id']}`, _service);
	}

	getId () {
    	return this.http.get(`${environment.baseUrl}/services/identifier`);
	}
	  
	getById (_id) {
    	return this.http.get(`${environment.baseUrl}/services/${_id}/edit`);
  	}

  	getServiceByType (_account, _type) {
	  	return this.http.get(`${environment.baseUrl}/services/${_account}/${_type}/get-by-type`);
	}
	
	getServiceByName (_account, _name) {
		return this.http.get(`${environment.baseUrl}/services/${_account}/${_name}/get-by-name`);
	}
}
