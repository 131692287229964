import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// Environment
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class UserService {

    constructor(private http: HttpClient) {}

    users () {
        return this.http.get(`${environment.baseUrl}/users`)
    }

    getUsersWithSalary () {
        return this.http.get(`${environment.baseUrl}/assignments`)
    }

    getPositionsWithUsersEmployee () {
        return this.http.get(`${environment.baseUrl}/positions/employee`)
    }

    getSalaryWithUserEmployee (_id) {
        return this.http.get(`${environment.baseUrl}/assignments/salary/${_id}`)
    }

    storeUserSalary (salary) {
        return this.http.post(`${environment.baseUrl}/assignments`, salary)
    }

    updateUserSalary (salary) {
        return this.http.put(`${environment.baseUrl}/assignments/${salary.id}`, salary)
    }

    positions () {
        return this.http.get(`${environment.baseUrl}/positions`)
    }

    save (_user) {
        return this.http.post(`${environment.baseUrl}/users`, _user)
    }

    update (_user, _id) {
        return this.http.put(`${environment.baseUrl}/users/${_id}`, _user)
    }

    assignments () {
        return this.http.get(`${environment.baseUrl}/assignments`)
    }

    changeState (_user) {
        return this.http.get(`${environment.baseUrl}/users/${_user['id']}/change-status`)
    }

    saveAssign (_user) {
        return this.http.put(`${environment.baseUrl}/assignments/${_user['id']}`, _user)
    }

    getById (_id) {
        return this.http.get(`${environment.baseUrl}/users/${_id}/edit`)
    }

    getByPosition (_postion_id) {
        return this.http.get(`${environment.baseUrl}/users/${_postion_id}/position`)
    }
}
