import { Injectable } from '@angular/core';
// Router
import { Router }  from '@angular/router';
// Http
import { HttpClient } from '@angular/common/http';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Notifier
import { NotifierService } from 'angular-notifier';
// Enviroments
import { environment } from '../environments/environment';

@Injectable({
  	providedIn: 'root'
})

export class Globals {
	  
	private readonly notifier: NotifierService;
	 
	constructor(private translate: TranslateService, private http: HttpClient, private _router: Router, public notifierService: NotifierService) {
    	this.notifier = notifierService
   	}

	/**
	 *  Método que genera notificación con mensajes que le envíes
	 *  pero ya tradicidos
	 * @param status (Success, Error, Warning)
	 */
	showNotify(status: string, message: string) {
		this.translate.get(message).subscribe((res: string) => {
			this.notifier.notify(status, res)
		})
	}
}
	