import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Environment
import { environment } from '../../../environments/environment';

@Injectable({
  	providedIn: 'root'
})

export class PayrollService {

	constructor(private http: HttpClient) {}

	all () {
        return this.http.get(`${environment.baseUrl}/assists`);
    }

    users () {
    	return this.http.get(`${environment.baseUrl}/assists/users`);
    }

    positionsWithUsers () {
        return this.http.get(`${environment.baseUrl}/assists/positions/users`);
    }

    assignmentsUser (_id) {
        return this.http.get(`${environment.baseUrl}/assists/assignments/${_id}/user`);
    }

    orders () {
        return this.http.get(`${environment.baseUrl}/assists/orders`);
    }

    saveAssists (_assistance) {
    	return this.http.post(`${environment.baseUrl}/assists`, _assistance);
    }

    saveHoursAssists (_assistance) {
        return this.http.post(`${environment.baseUrl}/assists/hours`, _assistance);
    }

    getById (_id) {
        return this.http.get(`${environment.baseUrl}/assists/${_id}/edit`);
    }

    getAssistsBetween (_payroll) {
        return this.http.post(`${environment.baseUrl}/payrolls/users-assists`, _payroll);
    }

    getConcepts () {
        return this.http.get(`${environment.baseUrl}/payrolls/concepts`);
    }

    validationPayroll (_payroll) {
        return this.http.post(`${environment.baseUrl}/payrolls/validation`, _payroll);
    }
}